import React from 'react';
import {
  Alert,
  Button,
  ButtonAppearance,
  Checkbox,
  Dialog,
  FormField,
  Heading, InlineAlert,
  IntentTypes,
  Pane,
  Paragraph,
  Text,
  TextInputField
} from "evergreen-ui";
import {FieldObject, GameEvent, parseGamesForTeam, TeamObject} from "./index";
import moment from "moment";
import {DateTime} from 'luxon';
import {
  useCancelGameMutation,
  useCreateGameSchedulingFormForGameMutation,
  useGameFormByIdQuery,
  useGamesByTeamQuery,
  useUpdateFormApprovalStatusForTeamMutation
} from "../../generated/graphql";
import {Collapse} from "@blueprintjs/core";
import {FieldSelect} from "./FieldSelect";
import {PickDate, PickTime} from "./DateTimeSelect";

export type GameSchedulingFormPopupProps = {
  teamId: number
  game: GameEvent
  onClose(): void
}

export const GameSchedulingFormPopup = (props: GameSchedulingFormPopupProps) => {

  const {
    teamId,
    game,
    onClose
  } = props;

  const { data } = useGamesByTeamQuery({
    variables: {
      teamId: teamId
    }
  })

  const [isCancelGamePanelOpen, setIsCancelGamePanelOpen] = React.useState<boolean>(false);

  const [isShowingSchedulingChangePopup, setIsShowingSchedulingChangePopup] = React.useState(false);

  if (!data) return null

  const foundGame = data.gamesForTeam.find(g => g.id === game.id)!

  // @ts-ignore
  const forms: GameSchedulingFormProps[] = foundGame.formHistory
    .filter(f => f.__typename === 'GameSchedulingForm')
    .map(form => {
        return {
          id: form.id,
          viewingTeamId: teamId,
          timeCreated: form.timeCreated,
          gameInitiatedBy: game.createdBy,
          createdBy: form.createdBy,
          opponent: foundGame.opponent,
          approvalStatus: form.approvalStatus,
          approvers: form.approvers,
          // @ts-ignore
          dateTime: form.gameDateTime,
          // @ts-ignore
          field: form.gameField,
          // @ts-ignore
          isHomeGameForEnteringTeam: form.isHomeGameForEnteringTeam
      }
    })

  let oldFormDetails: GameSchedulingFormDetails;

  if (game.schedulingDetails) {
    oldFormDetails = {
      date: game.schedulingDetails.dateTime,
      time: DateTime.fromJSDate(game.schedulingDetails.dateTime).toLocaleString(DateTime.TIME_24_SIMPLE),
      field: {
        id: game.schedulingDetails.field.id,
        name: game.schedulingDetails.field.name
      },
      isHomeGame: game.schedulingDetails.homeTeam.id === teamId
    }
  } else {
    oldFormDetails = {
      date: forms[0].dateTime,
      // @ts-ignore
      time: DateTime.fromISO(forms[0].dateTime).toLocaleString(DateTime.TIME_24_SIMPLE),
      field: {
        id: forms[0].field.id,
        name: forms[0].field.name
      },
      isHomeGame: forms[0].isHomeGameForEnteringTeam && (game.createdBy.id === teamId)
    }
  }

  return (
    <Dialog
      isShown={true}
      title={'Game History'}
      hasFooter={false}
      onCloseComplete={onClose}
    >

      {game.isCancelled && <Heading fontSize={'3em'} textAlign={'center'} color={'red'} marginBottom={16}>CANCELLED</Heading>}

      {game.schedulingDetails && <GamePreviewPanel game={game} />}

      {!(!game.schedulingDetails && (forms[0].approvalStatus === 'CLOSED' || forms[0].approvalStatus === 'DECLINED')) && !game.isCancelled &&
        <Pane display={'flex'} justifyContent={'space-around'}>
          {game.schedulingDetails && !game.isCancelled &&
            <Button
              appearance={'primary'}
              intent={'danger'}
              onClick={() => {
                setIsCancelGamePanelOpen(true)
              }}
            >
              Cancel Game
            </Button>
          }

          <Button
            appearance={'primary'}
            onClick={() => setIsShowingSchedulingChangePopup(true)}
          >
            Request Schedule Change
          </Button>
        </Pane>
      }

      <CancelGamePanel
        isShown={isCancelGamePanelOpen}
        gameId={game.id}
        onClose={() => {
          setIsCancelGamePanelOpen(false)
          onClose()
        }}
      />

      <SubmitGameSchedulingChange
        isShown={isShowingSchedulingChangePopup}
        onClose={() => setIsShowingSchedulingChangePopup(false)}
        teamId={teamId}
        opponentId={game.opponent.id}
        gameId={game.id}
        oldFormDetails={oldFormDetails}
      />

      {forms.map((form, idx) => {
        return (
          <Pane key={form.id} marginTop={10}>
            <GameSchedulingFormHistoryEntry showMoreInfoInitially={idx === 0} {...form} gameFormHistoryCount={game.formHistory.length} />
          </Pane>
        )
      })}
    </Dialog>
  )
}

function GamePreviewPanel(props: any) {

  const {
    game
  } = props

  return (
    <Pane display={'flex'} flexDirection={'column'} alignItems={'center'} marginBottom={16}>
      <Heading>{game.schedulingDetails.homeTeam.name} vs {game.schedulingDetails.awayTeam.name}</Heading>
      <Heading>{DateTime.fromJSDate(game.schedulingDetails.dateTime).toLocaleString(DateTime.DATETIME_FULL)}</Heading>
      <Heading>{game.schedulingDetails.field.name}</Heading>
      <br />
      <Heading textAlign={'center'}>Umpires: {formatUmpires(game.primaryUmpire, game.secondaryUmpire)}</Heading>
    </Pane>
  )
}

function formatUmpires(ump1: any, ump2: any) {
  let umpires = 'Unknown'
  if (ump1 && ump2) {
    umpires = `${ump1.firstName} ${ump1.lastName} (${ump1.phoneNumber}) and ${ump2.firstName} ${ump2.lastName} (${ump2.phoneNumber})`
  } else if (ump1) {
    umpires = `${ump1.firstName} ${ump1.lastName} (${ump1.phoneNumber})`
  } else if (ump2) {
    umpires = `${ump2.firstName} ${ump2.lastName} (${ump2.phoneNumber})`
  }

  return umpires
}

export type GameFormApprover = {
  team: TeamObject
  status: string
}

export type GameSchedulingFormProps = {
  id: number

  showMoreInfoInitially?: boolean

  viewingTeamId: number
  gameInitiatedBy: TeamObject
  timeCreated: Date

  createdBy: TeamObject
  opponent: TeamObject

  approvalStatus: string
  approvers: GameFormApprover[]

  dateTime: Date
  field: FieldObject
  isHomeGameForEnteringTeam: boolean

  gameFormHistoryCount: number
}

const GameSchedulingFormHistoryEntry = (props: GameSchedulingFormProps) => {

  const {
    id,
    showMoreInfoInitially,
    viewingTeamId,
    gameInitiatedBy,
    timeCreated,
    createdBy,
    opponent,
    approvalStatus,
    approvers,
    dateTime,
    field,
    isHomeGameForEnteringTeam,
    gameFormHistoryCount
  } = props

  const [showFullDetail, setShowFullDetail] = React.useState(showMoreInfoInitially ?? false);

  const [updateFormApprovalMutation] = useUpdateFormApprovalStatusForTeamMutation()
  const { data } = useGameFormByIdQuery({
    variables: {
      formId: id
    }
  })

  if (!data?.formById) return null

  let intent = 'none'

  if (data.formById.approvalStatus === 'PENDING') {
    intent = 'warning'
  } else if (data.formById.approvalStatus === 'APPROVED') {
    intent = 'success'
  } else {
    intent = 'danger'
  }

  let canApprove: boolean = false

  for (let i = 0; i < data.formById.approvers.length; i++) {
    const approvalEntry = data.formById.approvers[i]

    if (approvalEntry.team.id === viewingTeamId && approvalEntry.status === 'PENDING') {
      canApprove = true
      break
    }
  }

  if (approvalStatus !== 'PENDING') {
    canApprove = false
  }

  let canClose: boolean = viewingTeamId === createdBy.id && approvalStatus === 'PENDING'

  return (
    <Alert
      title={`Game Scheduling Form - ${data.formById.approvalStatus}`}
      hasIcon={false}
      // @ts-ignore
      intent={intent}
    >

      <Pane display={'flex'} justifyContent={'space-between'}>
        <Pane>
          <Paragraph>Submitted <b>{moment(timeCreated).startOf('minute').fromNow()}</b> by the <b>{createdBy.name}</b></Paragraph>
        </Pane>

          <Button marginTop={-14} onClick={() => setShowFullDetail(!showFullDetail)}>{showFullDetail ? 'Hide' : 'Show'} Details</Button>
      </Pane>

      <Collapse isOpen={showFullDetail}>

        <Pane height={20} />

        <Heading marginBottom={8}>Game Details:</Heading>

        <Pane display={'flex'}>
          <Text>Home Team</Text>
          <Pane flexGrow={1} borderBottom={'1px dotted lightgrey'}/>
          {/* @ts-ignore */}
          <Text>{isHomeGameForEnteringTeam ? gameInitiatedBy.displayName : opponent.displayName}</Text>
        </Pane>

        <Pane display={'flex'}>
          <Text>Opponent</Text>
          <Pane flexGrow={1} borderBottom={'1px dotted lightgrey'}/>
          {/* @ts-ignore */}
          <Text>{isHomeGameForEnteringTeam ? opponent.displayName : gameInitiatedBy.displayName}</Text>
        </Pane>

        <Pane display={'flex'}>
          <Text>Game Time</Text>
          <Pane flexGrow={1} borderBottom={'1px dotted lightgrey'}/>
          {/* @ts-ignore */}
          <Text>{DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_FULL)}</Text>
        </Pane>

        <Pane display={'flex'}>
          <Text>Field</Text>
          <Pane flexGrow={1} borderBottom={'1px dotted lightgrey'}/>
          <Text>{field.name}</Text>
        </Pane>

        <Heading marginBottom={8} marginTop={20}>Approvers:</Heading>
        {
          data.formById.approvers.map(approver => {
            return (
            <Pane key={approver.team.id} display={'flex'}>
              <Text>{approver.team.name}</Text>
              <Pane flexGrow={1} borderBottom={'1px dotted lightgrey'}/>
              <Text>{approver.status}</Text>
            </Pane>
            )
          })
        }

        {(canApprove || canClose) && <Pane height={24} />}

        {/* @ts-ignore */}
        {canApprove && (DateTime.now().startOf('hour') >= DateTime.fromISO(dateTime).minus({ hours: 24 }).startOf('hour')) && (
          <Alert intent={'danger'} marginBottom={8}>
            When approving a game within 24 hours of the game's start time we <b>cannot</b> guarantee umpires.
          </Alert>
        )}

        {/* @ts-ignore */}
        {canApprove && (
          <Pane display={'flex'} justifyContent={'space-around'}>
            <ButtonWithConfirmation
              title={'Decline Game'}
              confirmMessage={'Declining this game will inform your opponent that there is NO AGREEMENT to play. Use the contact page to discuss a time, day, and field to play.'}
              confirmLabel={'Decline Game'}
              intent={'danger'}
              appearance={'primary'}
              onConfirm={() => {
                updateFormApprovalMutation({
                  variables: {
                    formId: id,
                    status: 'DECLINED'
                  },
                  refetchQueries: ['GamesByTeamQuery']
                })
              }}
            >
              Decline Game
            </ButtonWithConfirmation>

            <ButtonWithConfirmation
              title={'Approve Game'}
              confirmMessage={'Approving this game will add the agreed game to the GHVBL master schedule for umpire assignment. Best of luck to both teams!'}
              confirmLabel={'Approve Game'}
              intent={'success'}
              appearance={'primary'}
              onConfirm={() => {
                updateFormApprovalMutation({
                  variables: {
                    formId: id,
                    status: 'APPROVED'
                  },
                  refetchQueries: ['GamesByTeamQuery']
                })
              }}
            >
              Approve Game
            </ButtonWithConfirmation>
          </Pane>
        )}

        {canClose && gameFormHistoryCount <= 1 && (
          <Pane display={'flex'} justifyContent={'space-around'}>
            <ButtonWithConfirmation
              title={'Withdraw Game'}
              confirmMessage={'Withdrawing this game will give you an opportunity to pull back your offer to play. Use the contact page to discuss a time, day, and field to play.'}
              confirmLabel={'Withdraw Game'}
              intent={'danger'}
              appearance={'primary'}
              onConfirm={() => {
                updateFormApprovalMutation({
                  variables: {
                    formId: id,
                    status: 'CLOSED'
                  },
                  refetchQueries: ['GamesByTeamQuery']
                })
              }}
            >
              Withdraw Proposed Game
            </ButtonWithConfirmation>
          </Pane>
        )}

      </Collapse>
    </Alert>
  )
}

interface ButtonWithConfirmationProps {
  title: string
  confirmMessage: string
  confirmLabel: string
  onConfirm:() => void
  intent?: IntentTypes
  appearance?: ButtonAppearance
}

const ButtonWithConfirmation: React.FunctionComponent<ButtonWithConfirmationProps> = (props) => {

  const {
    title,
    confirmMessage,
    confirmLabel,
    onConfirm,
    appearance,
    intent
  } = props

  const [isShowingConfirm, setIsShowingConfirm] = React.useState<boolean>(false);

  return (
    <>
      <Button
        appearance={appearance}
        intent={intent}
        onClick={() => setIsShowingConfirm(true)}>
        {props.children}
      </Button>

      <Dialog
        isShown={isShowingConfirm}
        onCloseComplete={() => setIsShowingConfirm(false)}
        title={title}
        confirmLabel={confirmLabel}
        intent={intent}
        shouldCloseOnOverlayClick={false}
        onConfirm={() => {
          onConfirm()
          setIsShowingConfirm(false)
        }}
      >
        {confirmMessage}
      </Dialog>
    </>
  )
}

type GameSchedulingFormDetails = {
  date: Date
  time: string
  field: FieldObject
  isHomeGame: boolean
}

type SubmitGameScheduleChangeProps = {
  isShown: boolean
  teamId: number
  opponentId: number
  gameId: number
  oldFormDetails: GameSchedulingFormDetails
  onClose(): void
}

const SubmitGameSchedulingChange = (props: SubmitGameScheduleChangeProps) => {

  const {
    isShown,
    oldFormDetails,
    teamId,
    opponentId,
    gameId,
    onClose
  } = props;

  const [date, setDate] = React.useState<Date>(moment(oldFormDetails.date).toDate());
  const [fieldId, setFieldId] = React.useState<number>(oldFormDetails.field.id);
  const [time, setTime] = React.useState<Date>(moment(oldFormDetails.time, 'HH:mm').toDate());
  const [isHomeGame, setIsHomeGame] = React.useState(oldFormDetails.isHomeGame);


  const [isSubmittingGame, setIsSubmittingGame] = React.useState<boolean>(false);


  const [createSchedulingFormMutation] = useCreateGameSchedulingFormForGameMutation()

  const { data, refetch } = useGamesByTeamQuery({
    variables: {
      teamId: teamId
    }
  })

  const createGame = () => {
    setIsSubmittingGame(true)
    createSchedulingFormMutation({
      variables: {
        gameId: gameId,
        data: {
          date: moment(date).format('YYYY-MM-DD'),
          time: moment(time).format('HH:mm'),
          fieldId: fieldId!,
          enteringTeamIsHomeTeam: isHomeGame
        },
        approvers: [{
          teamId: opponentId
        }]
      }
    }).finally(() => {
      setIsSubmittingGame(false)
      onClose()
    })
  }

  return (
    <Dialog
      isShown={isShown}
      onCloseComplete={onClose}
      title={'Request Schedule Change'}
      confirmLabel={'Submit Change'}
      onConfirm={createGame}
      isConfirmLoading={isSubmittingGame}
    >

      <Alert appearance={'card'} marginBottom={24} title={'Requesting a schedule change will update the game\'s details and require your opponent to re-approve the game. There is no need to withdraw the old game.'} />

      <Text>Field</Text>
      <FieldSelect
        defaultValue={{
          value: oldFormDetails.field.id,
          label: oldFormDetails.field.name,
        }}
        onChange={setFieldId}
      />

      <Pane height={24} />

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Game Date</Text>
        <PickDate
          defaultValue={moment(oldFormDetails.date).toDate()}
          updateDate={(e: Date) => {
            setDate(e)
          }}
          games={parseGamesForTeam(data?.gamesForTeam ?? [])}
        />
      </Pane>

      <Pane height={12} />

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Game Time</Text>
        <PickTime
          defaultValue={moment(oldFormDetails.time, 'HH:mm').toDate()}
          updateTime={(e: Date) => setTime(e)}
        />
      </Pane>

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Home Game?</Text>
        <Checkbox checked={isHomeGame} onChange={() => setIsHomeGame(!isHomeGame)} />
      </Pane>

    </Dialog>
  )
}

interface CancelGamePanelProps {
  isShown: boolean
  gameId: number
  onClose:() => void
}

function CancelGamePanel(props: CancelGamePanelProps) {

  const {
    isShown,
    gameId,
    onClose
  } = props

  const [cancellationReason, setCancellationReason] = React.useState<string>('');


  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [cancelGameMutation] = useCancelGameMutation()

  return (
    <Dialog
      isShown={isShown}
      title={'Cancel Game'}
      intent={'danger'}
      confirmLabel={'Cancel Game'}
      cancelLabel={'Go Back'}
      isConfirmLoading={isSubmitting}
      onCloseComplete={onClose}
      onConfirm={() => {
        setIsSubmitting(true)
        cancelGameMutation({
          variables: {
            gameId: gameId,
            cancellationReason: cancellationReason || null
          }
        }).then(() => {
          setIsSubmitting(false)
          onClose()
        })
      }}
    >
      <Alert intent={'danger'} title={'Confirm you are cancelling this game.'} marginBottom={10}>
        Any NON-WEATHER cancellation of a game(s) within 24 hours of first pitch is subject to FULL UMPIRE CREW payment (or payments in the case of a doubleheader) by the cancelling team.
      </Alert>

      <FormField
        label={'Cancellation Reason'}
      >
        <TextInputField
          value={cancellationReason}
          onChange={(e: any) => setCancellationReason(e.target.value)}
        />
      </FormField>
    </Dialog>
  )
}
