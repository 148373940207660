import React from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CloudDownloadIcon,
  CloudUploadIcon,
  Dialog,
  Heading,
  HelpIcon,
  ImportIcon,
  Pane,
  Paragraph
} from "evergreen-ui";

import {FilePond, FileStatus, registerPlugin} from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {Divider} from "@blueprintjs/core";
import {
  useAmIAdminQuery,
  useTeamRequirementsQuery,
  useUpdateTeamRequirementStatusMutation
} from "../../generated/graphql";

import {Document, Page, pdfjs} from 'react-pdf'

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import {setOptions} from "filepond";
import {FormHelpPopup} from "./formHelperPopups";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize);
setOptions({
  // @ts-ignore
  maxFileSize: '5MB'
})

var QRCode = require('qrcode.react')

export const UploadDocumentsPage = ({ teamId } : {teamId: number}) => {

  const { data } = useTeamRequirementsQuery({
    variables: {
      teamId: teamId
    }
  })

  const [isMobileQRCodeDialogOpen, setIsMobileQRCodeDialogOpen] = React.useState<boolean>(false);


  if (!data) return <div />

  const missingRequirementCount = data.team?.requirementStatus.requirementStatuses.filter(req => !req.isFulfilled).length || 0

  return (
    <Pane>
      <Heading size={800} marginBottom={30}>Upload Documents</Heading>

      {missingRequirementCount > 0 &&
      <Alert intent={'warning'}>Missing {missingRequirementCount} requirement{missingRequirementCount === 1 ? '' : 's'}</Alert>
      }

      <Button
        marginTop={20}
        onClick={() => {
          setIsMobileQRCodeDialogOpen(true)
        }}
      >
        View on Mobile
      </Button>

      <Dialog isShown={isMobileQRCodeDialogOpen} title={'View on Mobile'} hasFooter={false} onCloseComplete={() => setIsMobileQRCodeDialogOpen(false)}>
        <Pane display={'flex'} justifyContent={'center'}>
          <QRCode value={window.location.toString()} />
        </Pane>

        <Paragraph textAlign={'center'} marginTop={25}>
          Scan this QR Code to open the page on a mobile device.
          <br />
          Documents can be uploaded via your mobile device's camera.
        </Paragraph>
      </Dialog>

      <Pane display={'flex'} flexWrap={'wrap'} alignItems={'flex-start'}>
      {
        data.team?.requirementStatus.requirementStatuses.map(req =>
          <FileUploadItem
            key={req.requirement.id}
            teamId={teamId}
            requirementId={req.requirement.id}
            formDownloadUrl={req.requirement.formDownloadUrl ?? null}
            name={req.requirement.name!}
            hasBeenReceived={req.isFulfilled}
            fileUrl={req.fileUrl || null}
          />
        )
      }
      </Pane>
    </Pane>
  )
}

export type FileUploadItemProps = {
  teamId: number
  requirementId: number
  formDownloadUrl: string | null
  name: string
  hasBeenReceived: boolean
  fileUrl: string | null
}

const FileUploadItem = (props: FileUploadItemProps) => {

  const {
    teamId,
    requirementId,
    name,
    hasBeenReceived,
    formDownloadUrl,
    fileUrl
  } = props;

  const [fileHelpPopupId, setFileHelpPopupId] = React.useState<number | null>(null);

  const { data: amIAdminData } = useAmIAdminQuery()


  return (
    <Card display={'flex'} flexDirection={'column'} justifyContent={'space-between'} width={400} elevation={1} padding={10} paddingBottom={0} margin={24}>
      <Pane>
        <Pane display={'flex'} justifyContent={'space-between'}>
          <Pane display={'flex'} alignItems={'center'}>
            <Heading size={600}>{name}</Heading>
          </Pane>
          <Pane display={'flex'} alignItems={'center'}>
            {hasBeenReceived ? <Badge color={'green'} >Complete</Badge> : <Badge color={'orange'}>Missing</Badge>}
          </Pane>
        </Pane>

        <Pane display={'flex'} justifyContent={'space-between'} marginTop={10} alignItems={'center'}>
          <Button iconBefore={HelpIcon} onClick={() => setFileHelpPopupId(requirementId)}>More Info</Button>
          <FormHelpPopup formId={fileHelpPopupId} formName={name} onClose={() => setFileHelpPopupId(null)} />

          {amIAdminData?.amIAdmin && hasBeenReceived &&
          <Button is={fileUrl ? 'a' : undefined} href={fileUrl || ''} target={'_blank'} iconBefore={CloudDownloadIcon}>Download Form</Button>
          }
          {!amIAdminData?.amIAdmin && formDownloadUrl && (
            <Button is={'a'} href={formDownloadUrl} target={'_blank'} iconBefore={ImportIcon}>Download Form</Button>
          )}
        </Pane>
      </Pane>

      <Divider style={{margin: '10px 0'}} />

      {requirementId !== 4 &&
        <RequirementStatusFileUpload {...props} />
      }
      {requirementId === 4 &&
        <AgreeRequirementPane {...props} />
      }
    </Card>
  )
}

const AgreeRequirementPane = (props: FileUploadItemProps) => {

  const {
    teamId,
    requirementId,
    hasBeenReceived,
    formDownloadUrl,
    name
  } = props;

  const [isShowingContent, setIsShowingContent] = React.useState<boolean>(false);
  const [updateTeamRequirementStatusMutation] = useUpdateTeamRequirementStatusMutation()


  function onAgree() {
    updateTeamRequirementStatusMutation({
      variables: {
        teamId: teamId,
        requirementId: requirementId,
        status: true,
        fileId: null
      }
    }).then(() => setIsShowingContent(false))
  }


  return (
    <Pane>
      <Paragraph>
        Please review our <a style={{borderBottom: '1px dotted grey'}} href={formDownloadUrl!} target={'_blank'} rel={'noreferrer'}>{name}</a> and click the "agree" button below to agree to the policies outlined in the document.
      </Paragraph>

      <Pane display={'flex'} justifyContent={'center'} margin={20}>
        <Button
          disabled={hasBeenReceived}
          onClick={() => setIsShowingContent(true)}
          appearance={'primary'}
          intent={'success'}
        >
          Agree to {name}
        </Button>
      </Pane>

      <Dialog
        isShown={isShowingContent}
        title={name}
        onCloseComplete={() => setIsShowingContent(false)}
        intent={'success'}
        confirmLabel={'Agree'}
        onConfirm={onAgree}
      >
        <img src={'https://resources.ghvbl.com/forms/ghvbl-code-of-conduct.jpg'} alt={name} width={'100%'} />
      </Dialog>
    </Pane>
  )
}

const RequirementStatusFileUpload = (props: FileUploadItemProps) => {

  const {
    teamId,
    requirementId,
    name,
    hasBeenReceived,
    fileUrl
  } = props;

  const [canUploadFile, setCanUploadFile] = React.useState<boolean>(!hasBeenReceived);
  const [updateTeamRequirementStatusMutation] = useUpdateTeamRequirementStatusMutation()

  const { data: amIAdminData } = useAmIAdminQuery()

  const adminPreview = React.useMemo(() => <Pane width={'100%'}>
    {fileUrl?.endsWith('.pdf') ? (
      <Pane display={'flex'} justifyContent={'center'}>
        <Document
          file={{
            url: fileUrl,
            withCredentials: true
          }}
        >
          <Page pageNumber={1} width={350}/>
        </Document>
      </Pane>
    ) : (
      <img src={fileUrl || ''} alt={'submitted form'} style={{width: '100%', borderRadius: '8px'}} />
    )}
  </Pane>, [fileUrl])

  return (
    <Pane>
      <Pane paddingTop={6}>
        {!canUploadFile ? (
          <Pane display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} padding={10}>
            <CloudUploadIcon size={42} color={'muted'} />
            <Button marginTop={8} onClick={() => setCanUploadFile(true)}>Upload New File</Button>
          </Pane>
        ) : (
          <FilePond
            server={{
              url: process.env.REACT_APP_FILE_UPLOAD_ENDPOINT
            }}
            labelIdle={'Drag & Drop your file here or <span class="filepond--label-action">Browse</span>'}
            dropOnPage={true}
            allowRevert={false}
            onprocessfile={(error, file) => {
              if (!error && file.status === FileStatus.PROCESSING_COMPLETE) {
                updateTeamRequirementStatusMutation({
                  variables: {
                    teamId: teamId,
                    requirementId: requirementId,
                    status: true,
                    fileId: file.serverId
                  }
                })
              }
            }}
          />
        )}
      </Pane>

      {hasBeenReceived && canUploadFile && <Button marginX={'auto'} marginTop={-12} marginBottom={4} appearance={'minimal'} onClick={() => setCanUploadFile(false)}>Close</Button>}

      {amIAdminData?.amIAdmin && fileUrl &&
      <>
        <Divider />
        <Heading>Preview:</Heading>
        {adminPreview}
      </>
      }
    </Pane>
  )
}
