import React from 'react';
import Select from "react-select";

import {useAllFieldsQuery} from "../../generated/graphql";

export type FieldSelectProps = {
  defaultValue?: any
  onChange(fieldId: number): void
}

export const FieldSelect = (props: FieldSelectProps) => {

  const {
    defaultValue,
    onChange
  } = props;

  const { data } = useAllFieldsQuery()

  if (!data) return <div />

  return (
    <Select
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      defaultValue={defaultValue || null}
      options={data.fields.map(field => {
        return {
          value: field.id,
          label: field.name
        }
      })}
      onChange={(e: any) => {
        onChange(e.value)
      }}
    />
  )
}
