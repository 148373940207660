import React from 'react';
import {Button, Card, Heading, Pane, Text} from "evergreen-ui";
import {Classes, Divider, FormGroup, InputGroup} from "@blueprintjs/core";
import {useForm} from "react-hook-form";
import {useAuthenticateMutation, useVerifySessionQuery} from "../../../generated/graphql";
import {Link, useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";

type LoginInput = {
  usernameOrEmail: string,
  password: string
}

interface LocationState {
  from: {
    pathname: string;
  };
}

/**
 * Redirects user to home page if they are properly authenticated
 */
const useLoginGuard = () => {
  const { data } = useVerifySessionQuery({
    fetchPolicy: 'no-cache'
  });

  const history = useHistory()

  React.useEffect(() => {
    if (data?.verifySession) {
      history.replace('/home')
    }
  }, [data, history])

  return null
}

export const LoginPage = () => {

  useLoginGuard()

  const { register, handleSubmit, setError, errors } = useForm<LoginInput>()

  const history = useHistory()

  const location = useLocation<LocationState>()
  const { from } = location.state || { from: { pathname: '/home' } };

  const [authenticateMutation] = useAuthenticateMutation()
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = (data: any) => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement?.blur()
    }

    setIsSubmitting(true)
    authenticateMutation({
      variables: {
        usernameOrEmail: data.usernameOrEmail,
        password: data.password
      }
    }).then(res => {

      if (res?.data?.authenticate?.__typename === 'FailedAuthResponse') {
        setError('password', {
          type: 'server',
          // @ts-ignore
          message: res?.data?.authenticate.message
        })
      } else {
        history.replace(from)
      }
    }).finally(() => setIsSubmitting(false))
  }

  return (
    <Pane
      background={'blueTint'}

      position={'fixed'}
      top={0}
      left={0}
      bottom={0}
      right={0}
      overflowY={'auto'}
      display={'flex'}
      justifyContent={'center'}
      paddingTop={'25px'}
      paddingBottom={'40px'}
    >
      <Card
        background={'white'}
        elevation={2}
        padding={'18px'}
        minWidth={'200px'}
        maxWidth={'350px'}
        marginY={'auto'}
        marginX={'12px'}
        width={'100%'}
      >
        <img src={'https://resources.ghvbl.com/ghvbl_field.svg'} style={{marginLeft: '13.8%', userSelect: 'none'}} alt={'GHVBL Field'} />

        <div style={{height: '30px'}} />

        <Heading textAlign={'center'} size={800}>Sign In</Heading>

        <div style={{height: '30px'}} />


        <form
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormGroup
            label={'Email or Username'}
            style={{width: '100%'}}
          >
            <InputGroup
              name={'usernameOrEmail'}
              type={'text'}
              autoComplete={'username'}
              autoCorrect={'off'}
              inputRef={register({ required: true })}
            />
          </FormGroup>

          <FormGroup
            label={'Password'}
            style={{width: '100%'}}
          >
            <InputGroup
              name={'password'}
              type={'password'}
              autoComplete={'current-password'}
              inputRef={register({ required: true })}
            />
          </FormGroup>

          <div style={{height: '10px'}} />

          {errors?.password?.type === 'server' && <><Text textAlign={'center'} color={'danger'}>{errors?.password?.message}</Text><div style={{height: '20px'}} /></>}

          <Button isLoading={isSubmitting} type={'submit'} appearance={'primary'}>{isSubmitting ? 'Signing' : 'Sign'} in</Button>

          <div style={{height: '20px'}} />

          <Text size={300}><UnstyledLink to={'/request-password-reset'} className={Classes.TOOLTIP_INDICATOR}>Forgot Password?</UnstyledLink></Text>

          <div style={{height: '10px'}} />
          <Divider style={{width: '100%'}} />

          <div style={{height: '10px'}} />
          <Pane display={'flex'} justifyContent={'center'}>
            <Text size={300}>Need an account? <UnstyledLink className={Classes.TOOLTIP_INDICATOR} to={'/signup'}>Sign up</UnstyledLink>.</Text>
          </Pane>
        </form>
      </Card>
    </Pane>
  )
}

const UnstyledLink = styled(Link)`

  cursor: pointer;
  
  &:active {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
`
