import React, {useEffect} from 'react';
import {Button, Checkbox, Dialog, Pane, SegmentedControl, Text} from "evergreen-ui";
import FullCalendar, {EventInput} from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import luxonPlugin from '@fullcalendar/luxon'
import {TeamSelect} from "./TeamSelect";
import {FieldSelect} from "./FieldSelect";
import {PickDate, PickTime} from "./DateTimeSelect";
import moment from "moment";
import {Settings} from 'luxon';
import {
  GamesByTeamQuery,
  GameSchedulingForm,
  useAddGameMutation,
  useAllFieldsQuery,
  useAllTeamsQuery,
  useDeleteRawGameMutation,
  useGamesByTeamQuery,
  useUpdateRawGameMutation
} from "../../generated/graphql";
import {GameSchedulingFormPopup} from "./GameSchedulingFormPopup";

Settings.defaultZoneName = 'America/New_York'

export type ManageSchedulePanelProps = {
  teamId: number
  divisionId: number
  division: any
  teamLocation: any
  filterState: number
}

export type TeamObject = {
  id: number
  name: string
}

export type FieldObject = {
  id: number
  name: string
}

export type GameSchedulingDetails = {
  dateTime: Date
  field: FieldObject
  isHomeGame: boolean
  homeTeam: TeamObject
  awayTeam: TeamObject
}

export type GameEvent = {
  id: number
  createdBy: TeamObject
  opponent: TeamObject
  dateTime: Date
  schedulingDetails: GameSchedulingDetails | null
  formHistory: readonly any[]
  isCancelled: boolean
  primaryUmpire: any
  secondaryUmpire: any
}

export type GameCancellationStatus = {
  cancelReason: string | null
}

type UseTeamScheduleResponse = {
  isLoaded: boolean
  events: GameEvent[]
  eventInput: EventInput | null
  refetchGames(): void
}

const useTeamScheduleData = (teamId: number): UseTeamScheduleResponse => {
  const { data, refetch } = useGamesByTeamQuery({
    pollInterval: 60 * 1000, // every minute
    variables: {
      teamId: teamId
    }
  })

  if (!data) return {
    isLoaded: false,
    events: [],
    eventInput: null,
    refetchGames() {}
  }

  const games: GameEvent[] = data.gamesForTeam.map(game => {
    const {
      createdBy,
      opponent,
      schedulingDetails
    } = game


    let opponentTeam: TeamObject = {
      id: opponent.id,
      name: opponent.name
    }

    if (opponentTeam.id === teamId) {
      opponentTeam = {
        id: createdBy.id,
        name: createdBy.name
      }
    }

    let gameSchedulingDetails = null

    if (schedulingDetails) {
      gameSchedulingDetails = {
        date: new Date(moment(schedulingDetails.dateTime).toDate().toDateString()),
        time: moment(moment(schedulingDetails.dateTime).format('HH:mm'), 'HH:mm').toDate(),
        dateTime: moment(schedulingDetails.dateTime).toDate(),
        field: {
          id: schedulingDetails.field.id,
          name: schedulingDetails.field.name
        },
        isHomeGame: schedulingDetails.homeTeam.id === teamId,
        homeTeam: schedulingDetails.homeTeam,
        awayTeam: schedulingDetails.awayTeam
      }
    }

    return {
      id: game.id,
      createdBy: {
        id: game.createdBy.id,
        name: game.createdBy.name,
        displayName: game.createdBy.displayName
      },
      opponent: opponentTeam,
      schedulingDetails: gameSchedulingDetails,
      isCancelled: game.isCancelled,
      dateTime: schedulingDetails?.dateTime ?? function(): Date {
        const { formHistory } = game
        let res: Date | null = null

        for (let i = 0; i < formHistory.length; i++) {
          const currentForm = formHistory[i]

          if (currentForm.__typename === 'GameSchedulingForm' && currentForm.approvalStatus !== 'APPROVED') {
            res = currentForm.gameDateTime
            break
          }
        }

        return res!
      }(),
      formHistory: game.formHistory,
      primaryUmpire: game.primaryUmpire,
      secondaryUmpire: game.secondaryUmpire
    }
  })

  return {
    isLoaded: false,
    events: games,
    // @ts-ignore
    eventInput: games.map(game => {
      let confirmationClass: string = ''

      if (game.schedulingDetails) {
        confirmationClass = 'confirmed-game-event'
      } else {
        confirmationClass = 'unconfirmed-game-event'

        for (let i = 0; i < game.formHistory.length; i++) {
          const currentForm = game.formHistory[i]

          if (currentForm.__typename === 'GameSchedulingForm') {
            if (currentForm.__typename === 'GameSchedulingForm' && (currentForm.approvalStatus === 'CLOSED' || currentForm.approvalStatus === 'DECLINED')) {
              confirmationClass = 'rejected-game-event'
            }
            break
          }
        }
      }

      if (game.isCancelled) {
        confirmationClass = 'rejected-game-event'
      }

      // @ts-ignore
      const forms: any[] = game.formHistory.filter(f => f.__typename === 'GameSchedulingForm')

      return {
        id: game.id,
        title: (confirmationClass === 'unconfirmed-game-event' ? 'NEEDS APPROVAL - ' : '') + (game.schedulingDetails?.homeTeam?.id === teamId ? '' : '@ ') + game.opponent.name,
        start: game.dateTime,
        classNames: confirmationClass + ' ' + (forms[0].createdBy.id === teamId ? '' : 'not-my-game'),
        extendedProps: {
          gameEvent: game
        }
      }
    }).concat({
      id: -10,
      title: 'Fall 2021 Postseason Begins',
      start: new Date(2021, 9, 12, 0, 0, 0, 0),
      allDay: true
    }).concat({
      id: -10,
      title: 'Championship Weekend',
      start: new Date(2021, 9, 23, 0, 0, 0, 0),
      end: new Date(2021, 9, 25, 0, 0, 0, 0),
      allDay: true
    }),
    refetchGames() {
      if (refetch) {
        refetch()
      }
    }
  }
}

export const ManageSchedulePanel = (props: ManageSchedulePanelProps) => {

  const {
    teamId,
    divisionId,
    division,
    teamLocation,
    filterState
  } = props;

  useAllTeamsQuery()
  useAllFieldsQuery()

  const { isLoaded, eventInput, events, refetchGames } = useTeamScheduleData(teamId)
  const [selectedEvent, setSelectedEvent] = React.useState<GameEvent | null>(null);
  const [isCreateGameOpen, setIsCreateGameOpen] = React.useState<boolean>(false);


  const isPopperOpenRef = React.useRef<number | null>(null)

  const calendarRef = React.useRef<FullCalendar>(null)

  // @ts-ignore
  return (
    <Pane position={'relative'} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
      {/*<Heading size={800}>Manage Schedule</Heading>*/}

      <Pane zIndex={2} minWidth={1000} marginRight={20}>
        <FullCalendar
          ref={calendarRef}
          timeZone={'America/New_York'}
          plugins={[ dayGridPlugin, /*interactionPlugin,*/ listPlugin, luxonPlugin ]}
          headerToolbar={{
            start: 'dayGridMonth,listYear prev,next',
            center: 'title',
            end: 'addGame'
          }}
          customButtons={{
            addGame: {
              text: 'Add Game',
              click: function() {
                setIsCreateGameOpen(true)
              }
            },
            refreshCalendar: {
              text: 'Sync Calendar',
              click: function() {
                refetchGames()
              }
            }
          }}
          initialView="dayGridMonth"
          dayMaxEventRows={3}
          selectAllow={(e, _) => {
            return e.end.getTime() / 1000 - e.start.getTime() / 1000 <= 86400;
          }}
          // @ts-ignore
          eventDataTransform={(eventData) => {
            // Return custom, static events
            if (!eventData.extendedProps) return eventData

            // @ts-ignore
            const gameEvent = eventData.extendedProps.gameEvent

            if (gameEvent.createdBy.id !== teamId) {

            }

            if (filterState === 0) return eventData
            if (filterState === 1) {
              if (gameEvent.schedulingDetails) return eventData
              else return false
            }
            if (filterState === 2) {
              if (gameEvent.schedulingDetails || (gameEvent.formHistory[0].approvalStatus === 'CLOSED' || gameEvent.formHistory[0].approvalStatus === 'DECLINED')) return false
              else return eventData
            }

            return eventData
          }}
          validRange={{ start: moment('08/01/2021', 'L').toDate(), end: moment('11/30/2021', 'L').toDate() }}
          events={eventInput || []}
          editable={true}
          selectable={true}
          eventStartEditable={true}
          eventClick={e => {
            if (!e.event.extendedProps) return

            const gameEvent = e.event.extendedProps.gameEvent

            setSelectedEvent(gameEvent)

            // calendarRef.current?.getApi().unselect()
          }}
          select={e => {}}
          unselect={e => {}}
          unselectAuto={false}
          eventChange={(e) => {
            const gameEvent: GameEvent = e.event.extendedProps.gameEvent as GameEvent

            const newGameEvent = {
              ...gameEvent,
              date: e.event.start || gameEvent.dateTime,
              time: e.event.start || gameEvent.dateTime
            }

            /*updateRawGameMutation({
              variables: {
                gameId: gameEvent.id,
                data: {
                  opponentTeam: gameEvent.opponent.value,
                  date: moment(e.event.start).format('YYYY-MM-DD'),
                  time: moment(e.event.start).format('HH:mm'),
                  field: gameEvent.field.value,
                  isHomeGame: gameEvent.isHomeGame
                }
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateRawGame: {
                  __typename: "RawGameUpdates",
                  update: {
                    __typename: "RawGame",
                    id: newGameEvent.id,
                    enteringTeam: {
                      id: teamId,
                      name: '',
                    },
                    opponentTeam: {
                      id: newGameEvent.opponent.value,
                      name: newGameEvent.opponent.label,
                    },
                    field: {
                      id: newGameEvent.field.value,
                      name: newGameEvent.field.label,
                    },
                    date: moment(newGameEvent.date).format('YYYY-MM-DD'),
                    time: moment(newGameEvent.date).format('HH:mm'),
                    dateTime: e.event.start
                  }
                }
              }
            })*/
          }}
        />
      </Pane>

      {/*selectedEvent &&
        <EditGamePanel
          key={JSON.stringify(selectedEvent)}
          teamId={teamId}
          onClose={() => setSelectedEvent(null)}
          {...selectedEvent}
        />
      */}

      {(selectedEvent) &&
        <GameSchedulingFormPopup
          teamId={teamId}
          game={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      }

      {isCreateGameOpen &&
          <CreateGamePanel
            teamId={teamId}
            divisionId={divisionId}
            division={division}
            teamLocation={teamLocation}
            onCloseComplete={() => setIsCreateGameOpen(false)}
            onGameAdd={() => {
              refetchGames()
              setIsCreateGameOpen(false)
            }}
          />
      }
    </Pane>
  )
}


type CreateGamePanelProps = {
  teamId: number
  teamLocation: any
  divisionId?: number
  division?: any
  onCloseComplete(): void
  onGameAdd(): void
}

const CreateGamePanel = (props: CreateGamePanelProps) => {

  const {
    teamId,
    divisionId,
    division,
    teamLocation,
    onCloseComplete,
    onGameAdd
  } = props;

  const [date, setDate] = React.useState<Date | null>(null);

  const [opponentTeamId, setOpponentTeamId] = React.useState<number | null>(null);
  const [fieldId, setFieldId] = React.useState<number | null>(null);
  const [time, setTime] = React.useState<string | null>(null);
  const [isHomeGame, setIsHomeGame] = React.useState(false);


  const [isSubmittingGame, setIsSubmittingGame] = React.useState<boolean>(false);

  const [isDialogShown, setIsDialogShown] = React.useState(true);

  const [createRawGameMutation] = useAddGameMutation()

  const { data, refetch } = useGamesByTeamQuery({
    variables: {
      teamId: teamId
    }
  })

  const totalGames = totalGamesForTeam(data?.gamesForTeam ?? [])

  const MAX_GAMES = 50
  if (totalGames >= MAX_GAMES) {
    return (
      <Dialog
        isShown={isDialogShown}
        title={'Add Game'}
        onCloseComplete={() => {
          setIsDialogShown(false)
          onCloseComplete()
        }}
        confirmLabel={'OK'}
        hasCancel={false}
      >
        You have {MAX_GAMES} games either scheduled or needing attention. A game needs to be closed or declined to allow for another game to be added. Additional games
        can be added by emailing theghvbl@gmail.com.
      </Dialog>
    )
  }

  const createGame = () => {
    setIsSubmittingGame(true)
    createRawGameMutation({
      variables: {
        data: {
          enteringTeamId: teamId,
          opponentTeamId: opponentTeamId!,
          data: {
            date: moment(date).format('YYYY-MM-DD'),
            time: moment(time).format('HH:mm'),
            fieldId: fieldId!,
            enteringTeamIsHomeTeam: isHomeGame
          },
          approvers: [{
            teamId: opponentTeamId!
          }],
        }
      }
    }).finally(() => {
      setIsSubmittingGame(false)
      onGameAdd()
    })
  }

  return (
    <Dialog
      isShown={isDialogShown}
      title={'Add Game'}
      onCloseComplete={() => {
        setIsDialogShown(false)
        onCloseComplete()
      }}
      confirmLabel={'Add Game'}
      cancelLabel={'Cancel'}
      onConfirm={createGame}
      isConfirmLoading={isSubmittingGame}
      isConfirmDisabled={!(teamId && opponentTeamId && date && time)}
      shouldCloseOnEscapePress={false}
      shouldCloseOnOverlayClick={false}
    >
      <Text>Opponent</Text>
      <TeamSelect onChange={setOpponentTeamId} viewingTeamId={teamId} division={division} viewingTeamLocation={teamLocation} />

      <Pane height={12} />

      <Text>Field</Text>
      <FieldSelect onChange={setFieldId} />

      <Pane height={24} />

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Game Date</Text>
        <PickDate
          defaultValue={null}
          updateDate={(e: Date) => {
            setDate(e)
          }}
          games={parseGamesForTeam(data?.gamesForTeam ?? [])}
        />
      </Pane>

      <Pane height={12} />

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Game Time</Text>
        <PickTime
          defaultValue={null}
          updateTime={(e: string) => setTime(e)}
        />
      </Pane>

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Host?</Text>
        <Checkbox checked={isHomeGame} onChange={() => setIsHomeGame(!isHomeGame)} />
      </Pane>
    </Dialog>
  )
}

function totalGamesForTeam(games: GamesByTeamQuery['gamesForTeam']): number {
  const result = parseGamesForTeam(games)

  let total = 0
  for (const date in result) {
    if (result.hasOwnProperty(date)) {
      total += result[date]
    }
  }

  return total
}

export function parseGamesForTeam(games: GamesByTeamQuery['gamesForTeam']): any {
  const result: any = {}

  for (let i = 0; i < games.length; i++) {
    const curr = games[i]

    if (curr.schedulingDetails) {
      const date = moment(curr.schedulingDetails.dateTime).format('L')

      if (!result[date]) {
        result[date] = 0
      }

      result[date] = result[date] + 1
    } else {
      // @ts-ignore
      const date = moment(curr.formHistory[0].gameDateTime).format('L')

      if (!result[date]) {
        result[date] = 0
      }

      if (curr.formHistory[0].approvalStatus === 'CLOSED' || curr.formHistory[0].approvalStatus === 'DECLINED') {
        continue
      }

      result[date] = result[date] + 1
    }
  }

  return result
}

export type EditGamePanelProps = {
  teamId: number
  id: number
  opponent: TeamObject
  field: FieldObject
  date: Date
  time: Date
  isHomeGame?: boolean
  onGameEventUpdate?(gameEvent: GameEvent | null): void
  onClose?(): void
}

const EditGamePanel = (props: EditGamePanelProps) => {

  const {
    teamId,
    id,
    opponent,
    field,
    date,
    time,
    isHomeGame,
    onGameEventUpdate,
    onClose
  } = props;

  const [updateRawGameMutation] = useUpdateRawGameMutation()
  const [deleteRawGameMutation] = useDeleteRawGameMutation()

  const [gameTime, setGameTime] = React.useState(time);
  const [gameDate, setGameDate] = React.useState(date);
  const [gameOpponent, setGameOpponent] = React.useState(opponent.id);
  const [gameField, setGameField] = React.useState(field.id);

  const [isSubmittingChanges, setIsSubmittingChanges] = React.useState<boolean>(false);
  const [hasMadeChange, setHasMadeChange] = React.useState<boolean>(false);

  const [isConfirmingDelete, setIsConfirmingDelete] = React.useState<boolean>(false);


  const updateRawGame = () => {
    setIsSubmittingChanges(true)
    updateRawGameMutation({
      variables: {
        gameId: id,
        data: {
          opponentTeam: gameOpponent,
          date: moment(gameDate).format('YYYY-MM-DD'),
          time: moment(gameTime).format('HH:mm'),
          field: gameField,
          isHomeGame: isHomeGame || true
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateRawGame: {
          __typename: "RawGameUpdates",
          update: {
            __typename: "RawGame",
            id: id,
            enteringTeam: {
              id: teamId,
              name: '',
            },
            opponentTeam: {
              id: gameOpponent,
              name: '',
            },
            field: {
              id: gameField,
              name: '',
            },
            date: moment(gameDate).format('YYYY-MM-DD'),
            time: moment(gameTime).format('HH:mm'),
            dateTime: gameTime
          }
        }
      }
    }).finally(() => setIsSubmittingChanges(false))
  }

  return (
    <Dialog
      isShown={true}
      title={`Edit Game Details - ${moment(date).format('ll')}`}
      hasFooter={false}
      minHeightContent={500}
      onCloseComplete={() => {
        if (onClose) {
          onClose()
        }
      }}
    >
      <Text>Opponent</Text>
      <TeamSelect
        defaultValue={{
          value: opponent.id,
          label: opponent.name
        }}
        onChange={(e: any) => {
          setGameOpponent(e)
          setHasMadeChange(true)
        }}
      />

      <Pane height={12} />

      <Text>Field</Text>
      <FieldSelect
        defaultValue={{
          value: field.id,
          label: field.name
        }}
        onChange={e => {
          setGameField(e)
          setHasMadeChange(true)
        }}
      />

      <Pane height={24} />

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Game Date</Text>
        <PickDate
          defaultValue={date}
          updateDate={(e: Date) => {
            setGameDate(e)
            setHasMadeChange(true)
          }}
        />
      </Pane>

      <Pane height={12} />

      <Pane display={'flex'} alignItems={'center'}>
        <Text marginRight={20}>Game Time</Text>
        <PickTime
          defaultValue={gameTime}
          updateTime={(e: Date) => {
            setGameTime(e)
            setHasMadeChange(true)
          }}
        />
      </Pane>

      <Pane marginTop={36} display={'flex'} justifyContent={'space-around'}>
        <Button appearance={'primary'} intent={'success'} disabled={!hasMadeChange} isLoading={isSubmittingChanges} onClick={updateRawGame}>Update Game Details</Button>
        <Button appearance={'primary'} intent={'danger'} onClick={() => setIsConfirmingDelete(true)}>Delete Game</Button>
      </Pane>

      <Dialog intent='danger' isShown={isConfirmingDelete} title={'Delete Game'} confirmLabel={'Delete Game'} onCloseComplete={() => setIsConfirmingDelete(false)}
        onConfirm={() => {
          deleteRawGameMutation({
            variables: {
              gameId: id
            }
          }).then(() => {
            if (onGameEventUpdate) {
              onGameEventUpdate(null)
            }
          })
        }}
      >
        Are you sure you want to delete this game? This action cannot be undone.
      </Dialog>
    </Dialog>
  )
}


// Event popup trigger code
/*
    ReactDOM.render(
      // <TestPopoverCard {...e.event.extendedProps.gameEvent} />,
      <ApolloProvider>
        <EditGamePanel key={JSON.stringify(gameEvent)} {...gameEvent} onGameEventUpdate={(e) => {
          if (!e) {
            refetch()
            setSelectedEvent(null)
          }
        }} />
      </ApolloProvider>,
      document.getElementById('testt')!
    )


    // @ts-ignore
    const el = document.getElementById('testt')!
    // @ts-ignore
    el.style.display = 'block'

    createPopper(e.el, el, {
      placement: 'top',
    });
*/

/*
if (!isPopperOpenRef.current !== gameEvent.id) {
    ReactDOM.render(
      <ApolloProvider>
        <EditGamePanel key={JSON.stringify(gameEvent)} {...gameEvent} onGameEventUpdate={(e) => {
          if (!e) {
          }
        }}/>
      </ApolloProvider>,
      document.getElementById('testt')!
    )

    const el = document.getElementById('testt')!
    el.style.display = 'block'

    const popper = createPopper(e.el, el, {
      placement: 'top',
    });

    const listenerFunc = function (this: Document, event: MouseEvent) {
      const popupDims = el.getBoundingClientRect()

      const didClickInsidePopup =
        event.clientX > popupDims.x &&
        event.clientX < popupDims.x + popupDims.width &&
        event.clientY > popupDims.y &&
        event.clientY < popupDims.y + popupDims.height

      if (!didClickInsidePopup) {
        el.style.display = 'none'
        popper.destroy()
        isPopperOpenRef.current = null
        document.removeEventListener('click', listenerFunc)
      }
    }

    setTimeout(() => {
      document.addEventListener('click', listenerFunc)
    }, 250)

    isPopperOpenRef.current = gameEvent.id
  }
*/

export interface SchedulingFilterComponentProps {
  onChange:(value: number) => void
}

export function SchedulingFilterComponent(props: SchedulingFilterComponentProps) {

  const {
    onChange
  } = props

  const options = [
    {
      label: 'All Games',
      value: '0'
    },
    {
      label: 'Confirmed Games',
      value: '1'
    },
    {
      label: 'Unconfirmed Games',
      value: '2'
    }
  ]

  const [selected, setSelected] = React.useState<string>(options[0].value);

  useEffect(() => {
    onChange(parseInt(selected))
  }, [onChange, selected])

  return (
    <SegmentedControl
      width={400}
      marginLeft={2}
      marginBottom={8}
      value={selected}
      options={options}
      onChange={value => {
        //@ts-ignore
        setSelected(value)
      }}
    />
  )
}
