import React from "react";

import {ApolloClient, ApolloProvider as Apollo, createHttpLink, InMemoryCache, split} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from "@apollo/client/utilities";

const queryString = require('query-string')

let endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT

if (process.env.NODE_ENV === 'production'){
  endpoint = endpoint.replace('https://', 'wss://')
} else {
  endpoint = endpoint.replace('http://', 'ws://')
}

/*const wsLink = new WebSocketLink({
  uri: endpoint,
  options: {
    reconnect: true
  }
});*/

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
  const token = 'oiab7YSbCD8XFjKOEGtYihSFmc28vYlg'
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      'team-id': queryString.parse(window.location.search)['guid']
    }
  }
});

/**
 *  Injects children with Apollo Client
 */
export const ApolloProvider = ({children}) => {

  // const history = useHistory()
  // const location = useLocation()

  // const { from } = location.state || { from: { pathname: '/home' } };

  // Memoize client to avoid rerenders on children change
  const client = React.useMemo(() => {

    /*const handleAuthErrorLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        if (response.errors !== undefined) {
          for (const errIdx in response.errors) {
            if (response.errors[errIdx]?.extensions?.classification === 'Unauthorized') {
              history.replace({ pathname: '/login', state: from })
              break;
            }
          }
        }

        return response
      })
    });*/

    /*
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      authLink.concat(httpLink)
      // handleAuthErrorLink.concat(authLink).concat(httpLink)
    );
     */

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          OrderUpdates: {
            keyFields: []
          },
          RequirementUpdates: {
            keyFields: []
          },
          RawGameUpdates: {
            keyFields: []
          },
          TableInteractionEvent: {
            keyFields: ["clientId"]
          },
        }
      })
    });
  }, []);

  return (
    <Apollo client={client}>
      {children}
    </Apollo>
  );
};
