import React from 'react';
import {Button, Heading, Pane, Paragraph, Text, TextInput} from "evergreen-ui";
import {useResendPortalUrlsMutation} from "../../generated/graphql";

export function ForgotPortalUrls() {

  const [sendPortalUrlMutation] = useResendPortalUrlsMutation()

  const [email, setEmail] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  return (
    <Pane display={'flex'} justifyContent={'center'} padding={24} flexDirection={'column'} alignItems={'center'}>

      <Heading fontSize={'3rem'}>Retrieve Portal URLS - Fall 2021</Heading>

      {!hasSubmitted &&
        <>
          <Paragraph marginTop={16}>
            Enter your email address and we will send you an email with all of the portal urls associated with your address.
          </Paragraph>

          <Pane display={'flex'} flexDirection={'column'} marginTop={24}>
            <Text>Email Address</Text>

            <TextInput
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              type={'email'}
              placeholder={'Email'}
            />
          </Pane>

          <Button
            appearance={'primary'}
            marginTop={24}
            isLoading={isSubmitting}
            onClick={() => {
              setIsSubmitting(true)
              sendPortalUrlMutation({
                variables: {
                  email: email
                }
              }).then(() => {
                setHasSubmitted(true)
                setIsSubmitting(false)
              })
            }}
            disabled={hasSubmitted}
          >
            Submit
          </Button>
        </>
      }

      {hasSubmitted &&
        <>
          <Paragraph marginTop={24}>
            We've sent an email to <b>{email}</b> with your portal links. If you are still having issues please contact us at <i>theghvbl@gmail.com</i>.
          </Paragraph>
        </>
      }

    </Pane>
  )
}
