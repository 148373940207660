import React from 'react';
import Select from "react-select";

import {useAllTeamsInSeasonQuery} from "../../generated/graphql";

export type TeamSelectProps = {
  defaultValue?: any
  onChange(teamId: number): void
  viewingTeamId?: number
  division?: any
  viewingTeamLocation?: any
}

export const TeamSelect = (props: TeamSelectProps) => {

  const {
    defaultValue,
    onChange,
    viewingTeamId,
    division,
    viewingTeamLocation
  } = props;

  const { data } = useAllTeamsInSeasonQuery({
    variables: {
      viewingTeamId: viewingTeamId!
    }
  })

  if (!data) return <div />

  const teamChoices = data.teamsInSameSeason.filter(t => t.id !== viewingTeamId).map(team => {
    return {
      value: team.id,
      label: team.name + ((viewingTeamLocation && team.location) ? ` ~${computeDistanceFromLocations(viewingTeamLocation, team.location)} miles away` : ''),
      name: team.name,
      division: team.division,
      location: team.location
    }
  }).sort((a, b) => {
    if (a.division.rank === b.division.rank) {
      if ((a.division.modifier?.rank ?? 0) - (b.division.modifier?.rank ?? 0) === 0) {
        return (computeDistanceFromLocations(viewingTeamLocation, a.location) ?? 0) - (computeDistanceFromLocations(viewingTeamLocation, b.location) ?? 0)
      }
      return (a.division.modifier?.rank ?? 0) - (b.division.modifier?.rank ?? 0)
    }

    return a.division.rank - b.division.rank
  })

  let options: any[] = teamChoices

  if (division) {
    options = [
      {
        label: 'Teams In Your Division',
        options: teamChoices.filter(t => t.division.id === division.id)
      },
      {
        label: 'Teams Out of Your Division',
        options: teamChoices.filter(t => (t.division.rank === division.rank) && (t.division.id !== division.id))
      },
      {
        label: 'Other Teams',
        options: teamChoices.filter(t => (t.division.rank !== division.rank) && (t.division.rank >= (division.rank - 1)) && (t.division.rank <= (division.rank + 1)))
      }
    ]
  }

  return (
    <Select
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      defaultValue={defaultValue || null}
      options={options}
      onChange={(e: any) => {
        onChange(e.value)
      }}
      noOptionsMessage={() => 'No Teams'}
    />
  )
}

export interface Point {
  latitude: number
  longitude: number
}

interface Location {
  latitude: string
  longitude: string
}

export function computeDistanceFromLocations(l1: Location | null | undefined, l2: Location | null | undefined): number | null {
  if (!l1 || !l2) return null

  return computeDistance(
    {
      latitude: parseFloat(l1.latitude),
      longitude: parseFloat(l1.longitude),
    },
    {
      latitude: parseFloat(l2.latitude),
      longitude: parseFloat(l2.longitude),
    }
  )
}

export function computeDistance(p1: Point, p2: Point): number {

  const {
    latitude: lat1,
    longitude: long1
  } = p1

  const {
    latitude: lat2,
    longitude: long2
  } = p2

  const R = 6371e3
  const theta1 = lat1 * (Math.PI / 180)
  const theta2 = lat2 * (Math.PI / 180)

  const dTheta = (lat2 - lat1) * (Math.PI / 180)
  const dLambda = (long2 - long1) * (Math.PI / 180)

  const a = Math.sin(dTheta / 2) * Math.sin(dTheta / 2) +
    Math.cos(theta1) * Math.cos(theta2) *
    Math.sin(dLambda / 2) * Math.sin(dLambda / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return Math.round((R * c) / 1000 * 0.6213712)
}
