import React from 'react';
import {
  MeQuery,
  PortalUser,
  useAmIAdminQuery,
  useLogoutMutation,
  useOrdersForTeamQuery,
  usePortalHomeContentQuery,
  useSetIsCommittedToPlayoffsMutation,
  useTeamByGuidQuery,
  useTeamRequirementsQuery
} from "../../generated/graphql";
import {
  Avatar,
  Button,
  CalendarIcon,
  Card,
  CircleIcon,
  ClipboardIcon,
  CloudUploadIcon,
  CornerDialog,
  CrossIcon,
  Dialog,
  DollarIcon,
  Heading,
  HomeIcon,
  LogOutIcon,
  Menu,
  Pane,
  Paragraph,
  PersonIcon,
  Popover,
  SidebarTab,
  Spinner,
  Tablist,
  TabNavigation,
  Text,
  TickCircleIcon,
  TickIcon
} from "evergreen-ui";
import {Divider} from "@blueprintjs/core";
import {Link, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import styled from "styled-components";
import {PaymentPanel} from "../pay";
import {UploadDocumentsPage} from "../upload-documents";
import {useWindowDimensions} from "../../routerHooks";
import {ManageSchedulePanel, SchedulingFilterComponent} from "../manage-schedule";
import {ContactListPage} from "../contact-list";
import {DateTime} from "luxon";

const ReactMarkdown = require('react-markdown')
const gfm = require('remark-gfm')

const queryString = require('query-string')

export const HomePage = () => {

  const { data } = useTeamByGuidQuery({
    variables: {
      guid: queryString.parse(window.location.search)['guid']
    }
  })

  const { width } = useWindowDimensions()
  const isSmallerScreen = width < 1000
  const history = useHistory()

  const [showWrongPortalPop, setShowWrongPortalPop] = React.useState(false);

  React.useEffect(() => {
    if (data?.team && data?.team?.division.season.id !== 4) {
      setShowWrongPortalPop(true)
    }
  }, [data])

  if (!data || data?.team?.id === 3194) return <div />

  return (
    <Pane
      position={'fixed'}

      top={0}
      right={0}
      bottom={0}
      left={0}

      paddingTop={60}
    >
      <Dialog
        isShown={showWrongPortalPop}
        confirmLabel={'OK'}
        hasCancel={false}
        title={'You are in the Summer 2021 Portal!'}
      >
        You are viewing the page of a team that was a part of the Summer 2021 summer season. If you are trying to register
        for the Fall 2021 season please use your new portal url. If you lost your new url you can <a href={'https://portal.ghvbl.com/retrieve-portal-urls'}>get a new one here</a>.
      </Dialog>

      <SiteNavBar shouldShowHamburgerMenu={isSmallerScreen} />

      <Pane display={'flex'} height={'100%'}>

        <SiteSidebar />

        <Pane padding={'12px'} width={'calc(100% - 175px)'}>
          <HomePageRouter />

          <CornerDialog
            title={'The Fall 2021 Playoffs Are Coming!'}
            isShown={data.team?.isCommittedToPlayoffs === null && data.team?.division.season.id === 4 && (DateTime.now() <= DateTime.fromISO('2021-10-11T02:00:00Z'))}
            hasCancel={false}
            confirmLabel={'Respond Now'}
            onConfirm={() => history.push({ pathname: `/home`, search: window.location.search})}
          >
            The playoffs for the Fall 2021 season are just around the corner. Let us know if you are interested in participating by responding to the playoff form.
          </CornerDialog>
        </Pane>

      </Pane>
    </Pane>
  )
}

function HomePageRouter() {

  const { path } = useRouteMatch();

  const [showWrongPortalPop, setShowWrongPortalPop] = React.useState(false);


  const { data } = useTeamByGuidQuery({
    variables: {
      guid: queryString.parse(window.location.search)['guid']
    }
  })

  const { data: amIAdminData } = useAmIAdminQuery()

  const [filterChoice, setFilterChoice] = React.useState(0);

  React.useEffect(() => {
    if (data?.team?.division.season.id !== 4) {
      setShowWrongPortalPop(true)
    }
  }, [data])

  if (!data) return <div />

  return (
    <Switch>
      <Route exact path={'/home'}>
        <Heading marginBottom={10} size={800}>{data.team?.displayName}</Heading>
        <Heading size={600}>Head Coach: {data.team?.headCoachName}</Heading>

        <div style={{height: '20px'}} />

        <Pane display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          <PortalMessageBox />

          <Pane height={'24px'} />

          {data.team?.division.season.id === 4 &&
            <CommittedToPlayoffsBox
              teamId={queryString.parse(window.location.search)['guid']}
            />
          }

          <Pane height={'24px'} />

          <ChecklistCard teamId={data.team!.id} />

        </Pane>
      </Route>

      <Route exact path={`${path}/pay`}>
        <PaymentPanel teamId={data!.team!.id} />
      </Route>

      <Route exact path={`${path}/documents`}>
        <Pane height={'100%'} overflowY={'auto'}>
          <UploadDocumentsPage teamId={data!.team!.id} />
        </Pane>
      </Route>

      <Route exact path={`${path}/contacts`}>
        {false ?  (
        <Pane height={'100%'} overflowY={'auto'}>
          <ContactListPage viewingTeamId={data!.team!.id} ageGroupId={data!.team!.division.group.id} viewingTeamLocation={data!.team!.location} viewingTeamDivision={data!.team!.division} />
        </Pane>
        ) : (
          <>
            <Heading size={800}>Contact List - Coming Soon</Heading>

            <Pane height={20}/>

            <Text size={500}>The contact list for the {data!.team!.division!.season!.name} season is not available yet. We'll notify you when it is posted.</Text>
          </>
        )}
      </Route>

      <Route exact path={`${path}/schedule`}>
        {data!.team!.division!.season!.id === 4 || data!.team!.division!.season!.id === 2 ?  (
            <Pane width={'100%'} overflowX={'auto'} height={'100%'}>
              <SchedulingFilterComponent onChange={(v: number) => setFilterChoice(v)}/>
              <ManageSchedulePanel teamId={data!.team!.id} divisionId={data!.team!.division.id} division={data!.team!.division} filterState={filterChoice} teamLocation={data!.team!.location} />
            </Pane>
          ) : (
            <>
              <Heading size={800}>Schedule Manager - Coming Soon</Heading>

              <Pane height={20}/>

              <Text size={500}>Scheduling for the {data!.team!.division!.season!.name} season has not begun. We'll notify you via email when you can begin scheduling your games.</Text>
            </>
          )}
      </Route>
    </Switch>
  )
}

function SiteSidebar() {

  const { url } = useRouteMatch()

  const { pathname } = useLocation()

  const history = useHistory()

  const { data } = useTeamByGuidQuery({
    variables: {
      guid: queryString.parse(window.location.search)['guid']
    }
  })

  return (
    <Pane background={'tint1'} width={'175px'} height={'100%'}>
      <Tablist padding={'4px'} paddingTop={7}>

        <SidebarTab onSelect={() => history.push({ pathname: `/home`, search: window.location.search})} isSelected={'/home' === pathname}>
          <Pane display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingRight={'15px'}>
            <span>Home</span>
            <HomeIcon color={'#A7B6C2'} />
          </Pane>
        </SidebarTab>

        <Divider />

        <SidebarTab onSelect={() => history.push({ pathname: `${url}/pay`, search: window.location.search})} isSelected={useRouteMatch(`/home/pay`) !== null}>
          <Pane display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingRight={'15px'}>
            <span>Pay</span>
            <DollarIcon color={'#A7B6C2'} />
          </Pane>
        </SidebarTab>

        <SidebarTab onSelect={() => history.push({ pathname: `${url}/documents`, search: window.location.search})} isSelected={useRouteMatch(`/home/documents`) !== null}>
          <Pane display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingRight={'15px'}>
            <span>Upload Documents</span>
            <CloudUploadIcon color={'#A7B6C2'} />
          </Pane>
        </SidebarTab>

        <SidebarTab onSelect={() => history.push({ pathname: `${url}/schedule`, search: window.location.search})} isSelected={useRouteMatch(`/home/schedule`) !== null}>
          <Pane display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingRight={'15px'}>
            <span>Manage Schedule</span>
            <CalendarIcon color={'#A7B6C2'} />
          </Pane>
        </SidebarTab>

        <SidebarTab onSelect={() => history.push({ pathname: `${url}/contacts`, search: window.location.search})} isSelected={useRouteMatch(`/home/contacts`) !== null}>
          <Pane display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingRight={'15px'}>
            <span>Contacts</span>
            <PersonIcon color={'#A7B6C2'} />
          </Pane>
        </SidebarTab>

        {!(data?.team?.division?.season.id === 4 && (DateTime.now() >= DateTime.fromISO('2021-10-11T02:00:00Z') && DateTime.now() <= DateTime.fromISO('2021-10-12T18:00:00Z'))) &&
        <SidebarTab onSelect={() => window.location.assign(`https://info.ghvbl.com/report-score?t=${queryString.parse(window.location.search)['guid']}`)} isSelected={false}>
          <Pane display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingRight={'15px'}>
            <span>Report Game Score</span>
            <ClipboardIcon color={'#A7B6C2'}/>
          </Pane>
        </SidebarTab>
        }

      </Tablist>
    </Pane>
  )
}

type SiteNavBarProps = {
  shouldShowHamburgerMenu: boolean
}

function SiteNavBar(props: SiteNavBarProps) {

  const {
    shouldShowHamburgerMenu
  } = props;

  const [logoutMutation] = useLogoutMutation()
  const { data } = useTeamByGuidQuery({
    variables: {
      guid: queryString.parse(window.location.search)['guid']
    }
  })

  const { data: amIAdminResult } = useAmIAdminQuery()

  if (!data) return <div />

  return (
    <Pane position={'fixed'} top={0} left={0} right={0} zIndex={100} padding={'10px'} boxShadow={'0 0 1px rgba(67,90,111,.47), 0 2px 4px -2px rgba(67,90,111,.3)'}>
      <TabNavigation display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Heading
          size={800}
          onClick={() => {window.location.assign('https://ghvbl.com')}}
          cursor={'pointer'}
        >
          GHVBL {data.team?.division.season.name}
        </Heading>

        {/*shouldShowHamburgerMenu &&
          <IconButton height={36} appearance={'minimal'} icon={<MenuIcon size={24} />} />
        */}

        <Heading size={600}>{data.team?.displayName}</Heading>

        {amIAdminResult?.amIAdmin ?
        (<Popover
          content={
            <Menu>

              <Menu.Group>
                <Menu.Item icon={PersonIcon}>My Profile</Menu.Item>
              </Menu.Group>

              <Menu.Divider />

              <Menu.Group>
                <Menu.Item icon={LogOutIcon} intent={'danger'} onClick={() => logoutMutation().then(() => window.location.reload())}>Logout</Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <Avatar cursor={'pointer'} size={40} name={'Admin'} />
        </Popover>
        ) : (
          <Pane />
        )
        }
      </TabNavigation>
    </Pane>
  )
}


function getGreeting(data: { __typename?: 'PortalUser' } & Pick<PortalUser, 'id' | 'firstName' | 'lastName' | 'username' | 'email'>): string {
  const now = new Date()
  const hours = now.getHours()

  let name = data.username

  if (data.firstName) {
    name = data.firstName
  }

  if (hours < 11) {
    return 'Good Morning, ' + name
  } else if (hours < 17) {
    return 'Good Afternoon, ' + name
  } else {
    return 'Good Evening, ' + name
  }
}

function getProfileName(data: MeQuery["me"]): string {

  const {
    firstName,
    lastName,
    username
  } = data!!

  if (firstName !== null && lastName !== null) {
    return firstName + ' ' + lastName
  } else if (firstName !== null && firstName !== undefined) {
    return firstName
  }

  return username
}

function ChecklistCard({ teamId } : { teamId: number }) {

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const { data } = useOrdersForTeamQuery({
    variables: {
      teamId
    }
  })

  const { data: teamRequirementsData } = useTeamRequirementsQuery({
    variables: {
      teamId
    }
  })

  return (
    <Card elevation={2} padding={'10px'} width={'600px'}>

      <Pane>
        <Heading size={600} padding={10}>Complete Your Registration</Heading>
      </Pane>

      <Divider style={{margin: '15px 0'}}/>

      <Pane display={'flex'}>
        <Pane width={'50%'}>
          <Tablist>
            <SidebarTab height={50} isSelected={selectedTab === 0} onSelect={() => setSelectedTab(0)}>
              <ChecklistItem label={'Complete Payments'} isCompleted={data?.team?.financials.outstandingBalance.amount === '0'} />
            </SidebarTab>

            <Divider />

            <SidebarTab height={50} isSelected={selectedTab === 1} onSelect={() => setSelectedTab(1)}>
              <ChecklistItem label={'Upload Documents'} isCompleted={teamRequirementsData?.team?.requirementStatus.inGoodStanding || false} />
            </SidebarTab>

            <Divider />

            <SidebarTab height={50} isSelected={selectedTab === 2} onSelect={() => setSelectedTab(2)}>
              <ChecklistItem label={'Create Schedule'} isCompleted={false} />
            </SidebarTab>
          </Tablist>
        </Pane>

        <Pane padding={'10px'} width={'50%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          {selectedTab === 0 && <PayInstructions />}
          {selectedTab === 1 && <UploadDocumentsInstructions />}
          {selectedTab === 2 && <CreateScheduleInstructions />}
        </Pane>
      </Pane>
    </Card>
  )
}

function PayInstructions() {
  return (
    <Pane background={'tint'} textAlign={'center'}>
      <Heading marginBottom={10} size={700}>Complete Payments</Heading>
      <Paragraph marginBottom={10}>Resolve your outstanding payments</Paragraph>

      <UnstyledLink to={`/home/pay${window.location.search}`}>
        <Button appearance={'minimal'}>PAY NOW</Button>
      </UnstyledLink>
    </Pane>
  )
}

function UploadDocumentsInstructions() {
  return (
    <Pane background={'tint'} textAlign={'center'}>
      <Heading marginBottom={10} size={700}>Upload Documents</Heading>
      <Paragraph marginBottom={10}>Submit your missing documents</Paragraph>

      <UnstyledLink to={`/home/documents${window.location.search}`}>
        <Button appearance={'minimal'}>SUBMIT DOCUMENTS</Button>
      </UnstyledLink>
    </Pane>
  )
}

function CreateScheduleInstructions() {
  return (
    <Pane background={'tint'} textAlign={'center'}>
      <Heading marginBottom={10} size={700}>Create Schedule</Heading>
      <Paragraph marginBottom={10}>Create your schedule so you're ready to play when the season starts</Paragraph>

      <UnstyledLink to={`/home/schedule${window.location.search}`}>
        <Button appearance={'minimal'}>CREATE SCHEDULE</Button>
      </UnstyledLink>
    </Pane>
  )
}

type ChecklistItemProps = {
  label: string,
  isCompleted: boolean
}

function ChecklistItem(props: ChecklistItemProps) {
  const {
    label,
    isCompleted
  } = props

  return (
    <Pane display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingRight={'7px'}>
      <Heading size={500}>{label}</Heading>
      {isCompleted ? <TickCircleIcon size={24} color={'#15B371'} /> : <CircleIcon size={24} color={'#BFCCD6'} />}
    </Pane>
  )
}

const UnstyledLink = styled(Link)`

  cursor: pointer;

  &:active {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
`

function PortalMessageBox() {

  const { loading, data, error } = usePortalHomeContentQuery()

  if (loading) return <Spinner />
  if (error || !data || !data.portalHomeContent) return null

  return (
    <Card width={'750px'} elevation={1} padding={8}>
      <ReactMarkdown plugins={[gfm]}>{data.portalHomeContent}</ReactMarkdown>
    </Card>
  )
}

interface CommittedToPlayoffsBoxProps {
  teamId: string
}

function CommittedToPlayoffsBox(props: CommittedToPlayoffsBoxProps) {

  const {
    teamId
  } = props

  const teamData = useTeamByGuidQuery({
    variables: {
      guid: teamId
    },
    onCompleted(data) {
      // setIsOpen(data!.team!.isCommittedToPlayoffs === null)
    }
  })

  const [setIsCommittedToPlayoffs] = useSetIsCommittedToPlayoffsMutation()
  const [isOpen, setIsOpen] = React.useState(false);

  if (!teamData) return <div />

  const team = teamData.data!.team!

  let panelMessage
  if ((team.isCommittedToPlayoffs ?? null) === null) {
    panelMessage = 'You have not responded to the playoff form'
  } else if (team.isCommittedToPlayoffs) {
    panelMessage = 'You have committed to the playoffs. Good luck!'
  } else {
    panelMessage = 'You have opted out of the playoffs'
  }

  return (
    <Card width={'600px'} elevation={1} padding={8}>

      <Pane display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={600}>Commit to the Playoffs</Heading>
        {/*<Button appearance={'minimal'} marginLeft={8} onClick={() => setIsOpen(true)}>Review Rules</Button>*/}
      </Pane>

      <Dialog
        isShown={isOpen}
        title={'Commit to the GHVBL Playoffs'}
        hasFooter={false}
        onCloseComplete={() => setIsOpen(false)}
      >
        <Heading textAlign={'center'} marginBottom={8}>Please give your answer at the bottom of this form</Heading>

        <Paragraph style={{textAlign: 'center'}}>
          GHVBL playoffs - single elimination TOURNAMENT from 10/12 to 10/24
          Teams must have 6 decisions and are not playing in an outside tournament during the playoffs.
          <br /><br />
          If you have <strong>any</strong> conflict that impacts the bracket schedule & league playoff calendar - your team is subject to removal from the tourney.
          Upon receiving the playoff bracket on 10/11 - higher seed provides 2 or more day, time, field options to lower seed opponent. If high seed does not have a home field - low seed hosts.
          <br /><br />
          <strong>Teams enter & approve the game in the team portal so we can assign umpires.</strong>

          <br /><br />

          <strong>PLAYOFF SCHEDULE</strong>

          <br /><br />

          <Paragraph style={{textAlign: 'left', margin: '0 auto', width: '85%'}}>
            <strong>
              1st Round
            </strong> - on or before 10/16<br />

            <strong>
              2nd Round
            </strong> - on or before 10/17<br />

            <strong>
              3rd Round
            </strong> - on or before 10/23<br />

            <strong>
              4th Round (if necessary)
            </strong> - on or before 10/24<br />
          </Paragraph>

          <br />

          <Paragraph>
          If teams agree, we encourage teams to play <strong>before</strong> the deadlines - but must meet the deadline dates or not participate.
          Enter results via team portal immediately so we can update the brackets live and schedule your next opponent asap.
          We <strong>hope</strong> to host Championship games on 10/23 & 10/24 - more info to come.
          </Paragraph>

          <br />

          <Paragraph>
            <strong>IMPORTANT - Your team MUST commit to this schedule.</strong>
          </Paragraph>
        </Paragraph>

        <br />
        <Divider />
        <br />

        <Heading display={team.isCommittedToPlayoffs === null ? 'block' : 'none'} textAlign={'center'}>Will the <b>{team.displayName}</b> be participating in the GHVBL Playoff Tournament?</Heading>

        <Pane display={team.isCommittedToPlayoffs === null ? 'flex' : 'none'} justifyContent={'space-around'} marginTop={24}>
          <Button
            appearance={'primary'}
            intent={'danger'}
            iconAfter={CrossIcon}
            onClick={() => {
              setIsCommittedToPlayoffs({
                variables: {
                  teamId: team.id,
                  status: false
                }
              }).then(() => setIsOpen(false))
            }}
          >
            No
          </Button>

          <Button
            appearance={'primary'}
            intent={'success'}
            iconAfter={TickIcon}
            onClick={() => {
              setIsCommittedToPlayoffs({
                variables: {
                  teamId: team.id,
                  status: true
                }
              }).then(() => setIsOpen(false))
            }}
          >
            Yes
          </Button>
        </Pane>
      </Dialog>

      <Pane display={'flex'} flexDirection={'column'} alignItems={'center'} marginTop={12}>
        <Text>{panelMessage}</Text>

        {team.isCommittedToPlayoffs === null && false &&
          <Button
            onClick={() => window.location.reload()}
          >
            Respond to Form
          </Button>
        }
      </Pane>
    </Card>
  )
}
