import React from 'react';
import {Dialog, Pane, Paragraph} from "evergreen-ui";

export type FormHelpPopupProps = {
  formId: number | null
  formName: string
  onClose(): void
}

export const FormHelpPopup = (props: FormHelpPopupProps) => {

  const {
    formId,
    onClose,
    formName
  } = props

  let popupBody = <Pane />

  if (formId === 1) {
    popupBody = <WaiverFormHelpPopupBody />
  } else if (formId === 3) {
    popupBody = <RosterFormHelpPopupBody />
  } else if (formId === 2) {
    popupBody = <InsuranceFormHelpPopupBody />
  } else if (formId === 4) {
    popupBody = <CodeOfConductFormHelpPopupBody />
  }

  return (
    <Dialog
      isShown={formId !== null}
      onCloseComplete={onClose}
      title={formName}
      hasCancel={false}
      confirmLabel={'OK'}
    >
      {popupBody}
    </Dialog>
  )
}

const WaiverFormHelpPopupBody = () => {
  return (
    <Pane>
      <Paragraph>
        Please fill out and submit the following waiver & roster form. Please include <b>uniform numbers, first and last names, dates of birth, and parent signatures</b>.
      </Paragraph>
      <br />
      <Paragraph>
        <b>NOTE:</b> If you complete the Waiver/Roster form - you are not required to submit a roster as this will fulfill your waiver/roster requirement. Please upload this form
        again under the <b><i>Roster Form</i></b> if this applies to you.
      </Paragraph>
    </Pane>
  )
}

const RosterFormHelpPopupBody = () => {
  return (
    <Pane>
      <Paragraph>
        A Roster form is only required in the absence of Waiver form. Please note that you are not covered by GHVBL insurance without a completed waiver form.
      </Paragraph>
    </Pane>
  )
}

const InsuranceFormHelpPopupBody = () => {
  return (
    <Pane>
      <Paragraph>
        Please provide your team's proof of insurance here. Ask your insurance provider the following,
        <i> "Can you send us a Certificate of Insurance (COI) naming GHVBL as additionally insured?" </i>
        Insurance companies will ask for the address for the league - you can respond with:

        <br />
        <br />

        <Pane display={'flex'} justifyContent={'center'}>
          <Paragraph fontWeight={700}>
            GHVBL<br />
            PO BOX 841<br />
            YORKTOWN HEIGHTS, NY 10598<br />
          </Paragraph>
        </Pane>

      </Paragraph>
    </Pane>
  )
}

const CodeOfConductFormHelpPopupBody = () => {
  return (
    <Pane>
      <Paragraph>
        All coaches must review and agree to the
        <a style={{borderBottom: '1px dotted grey'}} href={'https://resources.ghvbl.com/forms/GHVBL-Code-of-Conduct.pdf'} target={'_blank'} rel={'noreferrer'}> GHVBL Code of Conduct</a>.
        Please read and submit your agreement to the policies and requirements in the document.
      </Paragraph>
    </Pane>
  )
}


