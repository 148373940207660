import React from "react";
import {Button} from "evergreen-ui";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {useTeamByGuidQuery} from "../../generated/graphql";
import {DateTime} from "luxon";

const queryString = require('query-string')

const DateTimeInput = ({ value, onClick, placeholder }) => (
  <Button
    onClick={onClick}
  >
    {value || placeholder}
  </Button>
);

export const PickDate = ({ defaultValue, updateDate, ...rest }) => {

  const {
    games
  } = rest

  /*
  let excludedDates = []
  if (games) {
    for (const date in games) {
      if (!games.hasOwnProperty(date)) continue

      if (games[date] >= 2 && moment(defaultValue).format('L') !== date) {
        excludedDates.push(moment(date, 'L').toDate())
      }
    }
  }
  */
  const { data } = useTeamByGuidQuery({
    variables: {
      guid: queryString.parse(window.location.search)['guid']
    }
  })

  const [date, setDate] = React.useState(defaultValue);

  if (!data) return <div />

  let minDate = new Date(new Date().setDate(new Date().getDate() + 1))
  let maxDate = new Date(2021, 5, 6, 0, 0, 0, 0)

  if (data?.team?.division?.season?.id === 2) {
    minDate = new Date(new Date().setDate(new Date().getDate() + 1)) // new Date(2021, 5, 5, 0, 0, 0, 0)
    if (DateTime.now() >= DateTime.fromISO('2021-07-26T20:00:00Z')) {
      minDate = new Date()
    }
    maxDate = new Date(2021, 7, 8, 0,  0, 0, 0)
  }

  if (data?.team?.division?.season?.id === 4) {
    minDate = new Date(new Date().setDate(new Date().getDate() + 2))

    if (DateTime.now() >= DateTime.fromISO('2021-10-10T14:00:00Z')) {
      minDate = new Date()
    }

    maxDate = new Date(2021, 9, 24, 0,  0, 0, 0)

    if (data?.team?.division?.id === 237) {
      maxDate = new Date(2021, 10, 7, 0,  0, 0, 0)
    }
  }

  const FancyInput = React.forwardRef((props, _) => (
    <DateTimeInput {...props} />
  ));

  return (
    <div>
      <DatePicker
        selected={date}
        onChange={date => {
          setDate(date)
          updateDate(moment(date).toDate())
        }}
        showDisabledMonthNavigation
        // minDate={moment(new Date(new Date().setDate(new Date().getDate() + 2))).isBefore(new Date(2021, 2, 26, 0, 0, 0, 0)) ? new Date(2021, 2, 26, 0, 0, 0, 0) : new Date(new Date().setDate(new Date().getDate() + 2))}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="MMMM d, yyyy"
        placeholderText={'Select Game Date'}
        disabledKeyboardNavigation
        // excludeDates={excludedDates}
        customInput={<FancyInput />}
      />
    </div>
  )
}

export const PickTime = ({ defaultValue, updateTime }) => {

  const [time, setTime] = React.useState(defaultValue);

  const FancyInput = React.forwardRef((props, _) => (
    <DateTimeInput {...props} />
  ));

  return (
    <div>
      <DatePicker
        selected={time}
        onChange={date => {
          if (date.getHours() === 0) {
            date.setTime(date.getTime() + (8*60*60*1000))
          }

          setTime(date)
          updateTime(moment(date).toDate())
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        minTime={new Date(2021, 5, 14, 8, 0, 0, 0)}
        maxTime={new Date(2021, 5, 14, 20, 30, 0, 0)}
        dateFormat="h:mm aa"
        placeholderText={'Choose Game Time'}
        disabledKeyboardNavigation
        customInput={<FancyInput />}
      />
    </div>
  )
}
