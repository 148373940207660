import React from 'react';
import {
  AddIcon,
  Button,
  Checkbox,
  Dialog,
  Heading,
  IconButton,
  Pane,
  RemoveIcon,
  SegmentedControl,
  Table,
  Text,
  TextInput,
  toaster
} from "evergreen-ui";
import {loadStripe} from "@stripe/stripe-js/pure";
import {
  OrdersForTeamDocument,
  useAmIAdminQuery,
  useCreateCheckoutForOrdersMutation,
  usePlaceOrderForTeamMutation, useUpdateOrderFulfilmentStatusMutation,
  useUpdateOrderPaymentStatusMutation,
  useUpdateOrderQuantityMutation,
} from "../../generated/graphql";
import styled from "styled-components";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

export type OrderTableProps = {
  teamId: number
  orders: OrderDetails[]
}

export type OrderDetails = {
  id: number
  productId: number
  productDescription: string
  unitPrice: number
  totalCost: number
  quantity: number
  haveReceivedPayment: boolean
  paymentNotes?: string | null
  wasFulfilled: boolean
}

export const OrderTable = (props: OrderTableProps) => {

  const {
    teamId,
    orders
  } = props;

  const [createCheckoutSession] = useCreateCheckoutForOrdersMutation()
  const [hasClickedPayButton, setHasClickedPayButton] = React.useState<boolean>(false);
  const [isOrderMoreBaseballsShown, setIsOrderMoreBaseballsShown] = React.useState<boolean>(false);


  const [selectedOrders, setSelectedOrders] = React.useState<OrderDetails[]>([]);

  const selectedOrderCount = selectedOrders.length
  const selectedOrderTotal = selectedOrders.map(o => o.totalCost).reduce((a, b) => parseFloat(String(a)) + parseFloat(String(b)), 0)

  const [shouldOnlyShowUnpaidOrders, setShouldOnlyShowUnpaidOrders] = React.useState<boolean>(true);

  const { data: amIAdminResponse } = useAmIAdminQuery()

  React.useEffect(() => {
    setSelectedOrders([...orders.filter(order => !order.haveReceivedPayment)])
  }, [])

  return (
    <Pane>
      <Pane display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingY={2} marginBottom={12}>
        <Pane display={'flex'} justifyContent={'space-between'} alignItems={'center'} opacity={amIAdminResponse?.amIAdmin ? 0 : 1}>
          <Button
            disabled={amIAdminResponse?.amIAdmin || selectedOrderCount === 0 || hasClickedPayButton}
            appearance={'primary'}
            intent={'success'}
            isLoading={hasClickedPayButton}
            onClick={async () => {
              setHasClickedPayButton(true)
              const stripe = await stripePromise

              const { data: sessionDetails } = await createCheckoutSession({
                variables: {
                  orderIds: selectedOrders.map(order => order.id)
                }
              })

              // @ts-ignore
              const result = await stripe.redirectToCheckout({
                // @ts-ignore
                sessionId: sessionDetails?.createCheckoutSessionForOrderIds,
              });

              if (result.error) {
                toaster.danger('Payment Failed', {
                  description: <span>Please <a href={'mailto:theghvbl@gmail.com'}>contact the GHVBL</a> for assistance</span>
                })
              }

              setHasClickedPayButton(false)
            }}
          >
            Pay Now
          </Button>

          {selectedOrderCount > 0 &&
            <Heading marginLeft={12}>{selectedOrderCount} items - ${(Math.round(selectedOrderTotal * 100) / 100).toFixed(2)}</Heading>
          }
        </Pane>

        <Pane display={'flex'}>
          <Button
            appearance={'primary'}
            onClick={() => setIsOrderMoreBaseballsShown(true)}
          >
            Buy Baseballs
          </Button>

          {isOrderMoreBaseballsShown &&
            <BuyBaseballsDialog teamId={teamId} onClose={() => setIsOrderMoreBaseballsShown(false)}/>
          }

          <Pane width={25} />

          <OrderTableFilterControl defaultSelection={orders.filter(order => !order.haveReceivedPayment).length === 0 || amIAdminResponse?.amIAdmin ? 'all' : 'unpaid'} onChange={(selection => setShouldOnlyShowUnpaidOrders(selection === 'unpaid'))} />
        </Pane>
      </Pane>

      <Table>
        <Table.Head>
          <Table.Cell flexBasis={50} flexGrow={0} />
          <Table.TextCell>Item</Table.TextCell>
          <Table.TextCell>Unit Price</Table.TextCell>
          <Table.TextCell>Quantity</Table.TextCell>
          <Table.TextCell>Total Cost</Table.TextCell>
          <Table.TextCell>Status</Table.TextCell>
          {amIAdminResponse?.amIAdmin &&
            <>
              <Table.TextCell>Notes</Table.TextCell>
              <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} />
              <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>Fulfilled?</Table.TextCell>
            </>
          }
        </Table.Head>

        <Table.Body>
          {orders.filter(order => !shouldOnlyShowUnpaidOrders || !order.haveReceivedPayment).map(order =>
            <OrderTableRow
              key={order.id}
              isSelected={selectedOrders.map(o => o.id).includes(order.id)}
              orderDetails={order}
              onSelect={(orderDetails) => {
                setSelectedOrders([...selectedOrders, order])
              }}
              onDeselect={(orderDetails) => setSelectedOrders([...selectedOrders].filter(o => o.id !== order.id))}
            />
          )}
        </Table.Body>
      </Table>
    </Pane>
  )
}

type OrderTableRowProps = {
  orderDetails: OrderDetails
  isSelected?: boolean
  onSelect(orderDetails: OrderDetails): void
  onDeselect(orderDetails: OrderDetails): void
}

const OrderTableRowWrapper = styled(Table.Row)`
  transition: all 125ms ease;
`

const OrderTableRow = (props: OrderTableRowProps) => {

  const {
    orderDetails,
    onSelect,
    onDeselect,
    isSelected
  } = props;

  const {
    id,
    productDescription,
    unitPrice,
    totalCost,
    quantity,
    haveReceivedPayment,
    paymentNotes: orderPaymentNotes,
    wasFulfilled
  } = orderDetails;

  const { data: amIAdminResponse } = useAmIAdminQuery()
  const [updateOrderMutation] = useUpdateOrderPaymentStatusMutation()
  const [paymentNotes, setPaymentNotes] = React.useState<string>(orderPaymentNotes || '');

  const [updateOrderQuantityMutation] = useUpdateOrderQuantityMutation()


  return (
    <OrderTableRowWrapper backgroundColor={isSelected && !amIAdminResponse?.amIAdmin ? '#E4E7EB' : 'white'}>
      <Table.Cell flexBasis={50} flexGrow={0}>
          <Checkbox
            checked={isSelected}
            disabled={(haveReceivedPayment || amIAdminResponse?.amIAdmin) ?? false}
            onChange={(e) => {
              if (haveReceivedPayment || amIAdminResponse?.amIAdmin) return
              isSelected ? onDeselect(props.orderDetails) : onSelect(props.orderDetails)
            }}
          />
      </Table.Cell>
      <Table.TextCell>{productDescription}{haveReceivedPayment ? '' : <> - <Text size={300} color={'red'}>UNPAID</Text></>}</Table.TextCell>
      <Table.TextCell isNumber>${unitPrice}</Table.TextCell>
      <Table.Cell>
        {quantity}
        {(!haveReceivedPayment && orderDetails.productId !== 1) &&
          <>
            <Pane width={20} />
            <OrderQuantityUpdateControl
              defaultQuantity={orderDetails.quantity}
              onChange={(newQuantity) => {
                updateOrderQuantityMutation({
                  variables: {
                    orderId: orderDetails.id,
                    quantity: newQuantity
                  }
                })
              }}
            />
          </>
        }
      </Table.Cell>
      <Table.TextCell isNumber>${totalCost}</Table.TextCell>
      <Table.TextCell>{haveReceivedPayment ? 'PAID' : 'UNPAID'}</Table.TextCell>
      {amIAdminResponse?.amIAdmin &&
        <>
          <Table.TextCell>{orderPaymentNotes || '-'}</Table.TextCell>

          <Table.Cell flexBasis={300} flexShrink={0} flexGrow={0}>
            <Button
              appearance={'minimal'}
              minWidth={120}
              onClick={() => {
                if (haveReceivedPayment) {
                  updateOrderMutation({
                    variables: {
                      orderId: id,
                      paymentStatus: false
                    }
                  })
                } else {
                  updateOrderMutation({
                    variables: {
                      orderId: id,
                      paymentStatus: true,
                      paymentNotes: paymentNotes
                    }
                  })
                }
              }}
            >
            Mark as {haveReceivedPayment ? 'Unpaid' : 'Paid'}
            </Button>

            {!haveReceivedPayment &&
              <TextInput
                placeholder={'Notes...'}
                value={paymentNotes}
                onChange={(e: any) => setPaymentNotes(e.target.value)}
              />
            }
          </Table.Cell>

          <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>
            <OrderFulfilmentCheckbox orderId={id} wasFulfilled={wasFulfilled} />
          </Table.TextCell>
        </>
      }
    </OrderTableRowWrapper>
  )
}

interface OrderFulfilmentCheckboxProps {
  orderId: number
  wasFulfilled: boolean
}

function OrderFulfilmentCheckbox(props: OrderFulfilmentCheckboxProps) {

  const {
    orderId,
    wasFulfilled
  } = props

  const [updateOrderFulfilmentStatus] = useUpdateOrderFulfilmentStatusMutation()

  return (
    <Checkbox
      checked={wasFulfilled}
      onChange={e => {
        updateOrderFulfilmentStatus({
          variables: {
            orderId: orderId,
            status: e.target.checked
          }
        })
      }}
    />
  )

}

type OrderTableFilterControlProps = {
  onChange(selection: string): void
  defaultSelection?: string
}

const OrderTableFilterControl = (props: OrderTableFilterControlProps) => {

  const {
    onChange,
    defaultSelection
  } = props;

  const [selectedValue, setSelectedValue] = React.useState<string>(defaultSelection || 'unpaid');

  React.useEffect(() => {
    onChange(defaultSelection || 'unpaid')
  }, [])

  return (
    <SegmentedControl
      name="switch"
      width={120}
      height={32}
      options={[
        {
          label: 'Unpaid',
          value: 'unpaid'
        },
        {
          label: 'All',
          value: 'all'
        }
      ]}
      value={selectedValue}
      onChange={value => {
        setSelectedValue(value.toString())
        onChange(value.toString())
      }}
    />
  )
}

type OrderQuantityUpdateControlProps = {
  defaultQuantity: number
  onChange(newQuantity: number): void
}

const OrderQuantityUpdateControl = (props: OrderQuantityUpdateControlProps) => {

  const {
    defaultQuantity,
    onChange
  } = props

  const [quantity, setQuantity] = React.useState<number>(defaultQuantity);

  React.useEffect(() => {
    console.debug('updating')
    onChange(quantity)
  }, [quantity])

  return (
    <Pane position={'relative'} zIndex={100} display={'flex'}>
      <IconButton appearance={'minimal'} icon={<RemoveIcon size={18} color={'danger'} />} onClick={() => {setQuantity(quantity > 1 ? quantity-1 : quantity)}} />
      <IconButton appearance={'minimal'} icon={<AddIcon size={18} color={'success'} />} onClick={() => {setQuantity(quantity+1)}} />
    </Pane>
  )
}

type BuyBaseballsDialogProps = {
  teamId: number
  onClose?(): void
}

const BuyBaseballsDialog = (props: BuyBaseballsDialogProps) => {

  const {
    teamId,
    onClose
  } = props

  const [quantity, setQuantity] = React.useState<number>(1);
  const [isPlacingOrder, setIsPlacingOrder] = React.useState<boolean>(false);


  const [createOrderMutation] = usePlaceOrderForTeamMutation()

  return (
    <Dialog
      isShown={true}
      title={'Order Baseballs'}
      onCloseComplete={() => {
        if (onClose) {
          onClose()
        }
      }}
      isConfirmLoading={isPlacingOrder}
      confirmLabel={'Place Order'}
      cancelLabel={'Cancel'}
      onConfirm={() => {
        setIsPlacingOrder(true)
        createOrderMutation({
          variables: {
            data: {
              teamId: teamId,
              productId: 2,
              quantity: quantity
            }
          },
          refetchQueries: [{
            query: OrdersForTeamDocument,
            variables: {
              teamId: teamId
            }
          }]
        }).finally(() => {
          if (onClose) {
            onClose()
          }
        })
      }}
    >
      <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <OrderQuantityUpdateControl defaultQuantity={quantity} onChange={setQuantity} />
        <Pane width={16} />
        <Heading size={800}>{quantity}</Heading>
        <Heading size={500} marginLeft={12}>dozen baseballs @ $42.00</Heading>
      </Pane>
    </Dialog>
  )
}
