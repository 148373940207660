import React, {Suspense} from 'react';
import './App.css';
import {ApolloProvider} from "./ApolloProvider";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Heading, Spinner} from "evergreen-ui";
import {HomePage} from "./components/home";
import {LoginPage} from "./components/auth/login-page";
import {ForgotPortalUrls} from "./components/forgot-portal-urls";


function App() {
  return (
    <Router>
      <ApolloProvider>
        <Suspense fallback={<Spinner />}>
          <Switch>

            <Route path={'/home'} component={HomePage}/>
            <Route key={'/login'} exact path={'/login'} component={LoginPage} />
            <Route path={'/retrieve-portal-urls'} exact component={ForgotPortalUrls} />

            <Route path={'*'}><Heading size={800} textAlign={'center'}>404</Heading></Route>

          </Switch>
        </Suspense>
      </ApolloProvider>
    </Router>
  );
}

export default App;
