import React from 'react';
import {Heading, Pane, toaster} from "evergreen-ui";
import {useOrdersForTeamQuery} from "../../generated/graphql";
import {OrderDetails, OrderTable} from "./orderTable";

export const PaymentPanel = ({ teamId } : {teamId: number}) => {

  const { data } = useOrdersForTeamQuery({
    variables: {
      teamId
    }
  })

  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      toaster.success('Payment Received!', {
        id: 'payment-toast',
        description: 'You will be sent an email receipt shortly.'
      });
    }

    return () => {
      query.delete('success')
      window.location.search = query.toString()
    }

  }, [])

  if (!data) return <div />

  const orders: OrderDetails[] = data.team!.financials.orders.map(order => {
    return {
      id: order.id,
      productId: order.productDetails.id,
      productDescription: order.productDetails.name,
      unitPrice: order.productDetails.unitPrice.amount,
      totalCost: order.totalCost.amount,
      quantity: order.quantity,
      haveReceivedPayment: order.didReceivePayment,
      paymentNotes: order.paymentNotes,
      wasFulfilled: order.wasFulfilled
    }
  })

  return (
    <Pane>
      <Heading size={800} marginBottom={25}>Manage Payments</Heading>

      <OrderTable teamId={teamId} orders={orders} />
    </Pane>
  )
}


